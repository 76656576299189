<template>
  <div>
    <v-row class="ma-0 mt-4">
      <v-col
        cols="12"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Early exit multiple'"
        >
          <common-input-currency-input
            v-model="internalValue.early_exit_multiple"
            label="Early exit multiple"
            color="primary"
            :error-messages="errors"
            :options="{ locale: 'en-US', precision: 2 }"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row class=" ma-0">
      <v-col
        cols="12"
        class="pt-0 pb-0"
      >
        <common-input-switch
          v-model="hasWarrantCoverage"
          label="Warrant Coverage"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="hasWarrantCoverage"
      class=" ma-0 mt-4"
    >
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Coverage price cap'"
          :rules="{ required: true, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="warrantCoverageData.coverage_price_cap"
            label="Coverage price cap"
            color="primary"
            :error-messages="errors"
            :options="{ locale: 'en-US', precision: 2 }"
            prepend-inner-icon="mdi-currency-usd"
            @input="update"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Coverage type'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="warrantCoverageData.coverage_type"
            label="Coverage type"
            placeholder="Select coverage type"
            :items="CONSTS.DEBT_CONVERTIBLE_COVERAGE_TYPES"
            item-text="title"
            item-value="value"
            :error-messages="errors"
            @change="update"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Rate'"
          :rules="{ required: true }"
        >
          <common-input-currency-input
            v-model="warrantCoverageData.rate"
            label="Rate"
            color="primary"
            :error-messages="errors"
            :options="{ locale: 'en-US', precision: 2 }"
            append-icon="mdi-percent-outline"
            @input="update"
          />
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'DebtConvertibleForm',

    props: {
      value: {
        type: Object,
        required: true,
        default: () => {},
      },
    },

    data: () => ({
      hasWarrantCoverage: false,
      warrantCoverageData: {},
    }),

    computed: {
      internalValue: {
        get () {
          return this.value || {}
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      internalValue: {
        handler (val) {
          if (val) {
            this.warrantCoverageData = val.warrant_coverage ? val.warrant_coverage : {}
            this.hasWarrantCoverage = !!val.warrant_coverage
          }
        },
        immediate: true,
      },
    },

    methods: {
      update () {
        this.internalValue = { ...this.internalValue, warrant_coverage: this.warrantCoverageData }
      },
    },
  }
</script>
