var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-row',{staticClass:"ma-0 mt-4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Share term',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Share Term","placeholder":"Select share term to convert into","items":_vm.shareTerms,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0},on:{"change":_vm.updateValue},model:{value:(_vm.value.share_terms_id),callback:function ($$v) {_vm.$set(_vm.value, "share_terms_id", $$v)},expression:"value.share_terms_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Convertible type',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Convertible Type","placeholder":"Select convertible type","items":_vm.CONSTS.CONVERTIBLE_TYPES_LIST,"item-text":"title","item-value":"value","error-messages":errors,"hide-details":errors.length === 0},on:{"change":_vm.updateType},model:{value:(_vm.value.convertible_type),callback:function ($$v) {_vm.$set(_vm.value, "convertible_type", $$v)},expression:"value.convertible_type"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Financing round',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Financing Round","placeholder":"Financing Round","items":_vm.financingRounds,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0},on:{"change":_vm.updateValue},model:{value:(_vm.value.financing_round_id),callback:function ($$v) {_vm.$set(_vm.value, "financing_round_id", $$v)},expression:"value.financing_round_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Name',"rules":{ required: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Name","color":"primary","error-messages":errors,"hide-details":errors.length === 0},on:{"input":_vm.updateValue},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}})]}}])})],1),_c('v-col',{staticClass:"mb-n1",attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Prefix',"rules":{ required: true, max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Prefix","color":"primary","error-messages":errors,"hide-details":errors.length === 0},on:{"input":_vm.updateValue},model:{value:(_vm.value.prefix),callback:function ($$v) {_vm.$set(_vm.value, "prefix", $$v)},expression:"value.prefix"}})]}}])})],1),_c('v-col',{staticClass:"mb-n1",attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Board approval date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"hide-details":errors.length === 0,"input-templ":{label: 'Board approval date', mandatory: true},"clearable":""},on:{"change":_vm.updateValue},model:{value:(_vm.boardApprovalDate),callback:function ($$v) {_vm.boardApprovalDate=$$v},expression:"boardApprovalDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Valuation cap',"rules":{ min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Valuation Cap (Optional)","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},on:{"input":_vm.updateValue},model:{value:(_vm.value.valuation_cap),callback:function ($$v) {_vm.$set(_vm.value, "valuation_cap", $$v)},expression:"value.valuation_cap"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Conversion discount',"rules":{ min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Conversion Discount (Optional)","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"hint":'Example: 10.00 %',"persistent-hint":"","options":{ locale: 'en-US', precision: 2 },"append-icon":"mdi-percent-outline"},on:{"input":_vm.updateValue},model:{value:(_vm.conversionDiscount),callback:function ($$v) {_vm.conversionDiscount=$$v},expression:"conversionDiscount"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Conversion trigger',"rules":{ min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Conversion Trigger (Optional)","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},on:{"input":_vm.updateValue},model:{value:(_vm.value.conversion_trigger),callback:function ($$v) {_vm.$set(_vm.value, "conversion_trigger", $$v)},expression:"value.conversion_trigger"}})]}}])})],1)],1),(_vm.isDebtType)?_c('interest-form',{on:{"update":_vm.updateInterest},model:{value:(_vm.interestTerms),callback:function ($$v) {_vm.interestTerms=$$v},expression:"interestTerms"}}):_vm._e(),(_vm.isDebtType)?_c('debt-convertible-form',{model:{value:(_vm.convertibleDebtTerms),callback:function ($$v) {_vm.convertibleDebtTerms=$$v},expression:"convertibleDebtTerms"}}):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('notes-and-documents-form',{attrs:{"id":_vm.convertibleTermId,"type":_vm.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLE_TERMS,"mode":_vm.mode,"internal-dialog":_vm.internalDialog,"is-submit":_vm.isSubmit},on:{"navToList":function($event){return _vm.$emit('navToList', true)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }