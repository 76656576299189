<template>
  <div>
    <v-row class=" ma-0">
      <v-col
        cols="12"
        class="pt-0 pb-0"
      >
        <common-input-switch
          v-model="hasInterest"
          label="Interest"
          @change="updateInterest"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="hasInterest"
      class=" ma-0"
    >
      <v-col
        cols="12"
        class="pt-0 pb-0"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Interest Rate'"
          :rules="{ required: true }"
        >
          <v-radio-group
            v-model="value.type"
            column
            :error-messages="errors"
            @change="update"
          >
            <v-radio
              v-for="(item, key) of CONSTS.INTERST_TYPES"
              :key="key"
              :label="item.title"
              color="primary"
              :value="item.value"
            />
          </v-radio-group>
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Interest Rate'"
          :rules="{ required: true }"
        >
          <common-input-currency-input
            v-model="value.rate"
            label="Interest Rate"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :options="{ locale: 'en-US', precision: 2 }"
            append-icon="mdi-percent-outline"
            @input="update"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Time period'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="value.period"
            label="Time Period"
            placeholder="Select time period"
            :items="CONSTS.INTEREST_PERIOD"
            item-text="title"
            item-value="value"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @change="update"
          />
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'InterestForm',

    props: {
      value: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      hasInterest: false,
    }),

    watch: {
      value: {
        handler (val) {
          this.hasInterest = Object.keys(val).length !== 0
        },
        immediate: true,
      },
    },

    methods: {
      update () {
        this.$emit('update', this.value)
      },
      updateInterest (val) {
        if (!val) {
          this.$emit('update', null)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep .v-input--radio-group__input {
    flex-direction: row !important;
  }
  ::v-deep .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 0 !important;
    margin-right: 20px;
  }
</style>
