<template>
  <v-form ref="form">
    <v-row class="ma-0 mt-4">
      <v-col cols="6">
        <validation-provider
          v-slot="{ errors }"
          :name="'Share term'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="value.share_terms_id"
            label="Share Term"
            placeholder="Select share term to convert into"
            :items="shareTerms"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @change="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Convertible type'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="value.convertible_type"
            label="Convertible Type"
            placeholder="Select convertible type"
            :items="CONSTS.CONVERTIBLE_TYPES_LIST"
            item-text="title"
            item-value="value"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @change="updateType"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Financing round'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="value.financing_round_id"
            label="Financing Round"
            placeholder="Financing Round"
            :items="financingRounds"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @change="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Name'"
          :rules="{ required: true, max: 255 }"
        >
          <common-input-text-field
            v-model="value.name"
            label="Name"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
        class="mb-n1"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Prefix'"
          :rules="{ required: true, max: 20 }"
        >
          <common-input-text-field
            v-model="value.prefix"
            label="Prefix"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
        class="mb-n1"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Board approval date'"
          :rules="{ required: true }"
        >
          <input-template-date
            v-model="boardApprovalDate"
            :mask="'##/##/####'"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :input-templ="{label: 'Board approval date', mandatory: true}"
            clearable
            @change="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Valuation cap'"
          :rules="{ min_value: 0 }"
        >
          <common-input-currency-input
            v-model="value.valuation_cap"
            label="Valuation Cap (Optional)"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :options="{ locale: 'en-US', precision: 2 }"
            prepend-inner-icon="mdi-currency-usd"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Conversion discount'"
          :rules="{ min_value: 0 }"
        >
          <common-input-currency-input
            v-model="conversionDiscount"
            label="Conversion Discount (Optional)"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :hint="'Example: 10.00 %'"
            persistent-hint
            :options="{ locale: 'en-US', precision: 2 }"
            append-icon="mdi-percent-outline"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Conversion trigger'"
          :rules="{ min_value: 0 }"
        >
          <common-input-currency-input
            v-model="value.conversion_trigger"
            label="Conversion Trigger (Optional)"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :options="{ locale: 'en-US', precision: 2 }"
            prepend-inner-icon="mdi-currency-usd"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <!-- Debt Specific Fields -->
    <interest-form
      v-if="isDebtType"
      v-model="interestTerms"
      @update="updateInterest"
    />

    <debt-convertible-form
      v-if="isDebtType"
      v-model="convertibleDebtTerms"
    />

    <v-col cols="12">
      <notes-and-documents-form
        :id="convertibleTermId"
        :type="CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLE_TERMS"
        :mode="mode"
        :internal-dialog="internalDialog"
        :is-submit="isSubmit"
        @navToList="$emit('navToList', true)"
      />
    </v-col>
  </v-form>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import InterestForm from './InterestForm'
  import NotesAndDocumentsForm from './NotesAndDocumentsForm.vue'
  import DebtConvertibleForm from './DebtConvertibleForm'

  export default {
    name: 'ConvertibleTermForm',

    components: {
      InterestForm,
      NotesAndDocumentsForm,
      InputTemplateDate,
      DebtConvertibleForm,
    },

    props: {
      financingRounds: {
        type: Array,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
      shareTerms: {
        type: Array,
        required: true,
      },
      convertibleTermId: {
        type: String,
      },
      mode: {
        type: String,
        default: 'create',
      },
      isSubmit: {
        type: Boolean,
        default: false,
      },
      internalDialog: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      datePickerAttr: { max: new Date().toISOString() },
      conversionDiscount: 0,
      interestTerms: {},
      notesData: {},
      boardApprovalDate: '',
      convertibleDebtTerms: {},
    }),

    computed: {
      isDebtType () {
        return this.value.convertible_type === this.CONSTS.CONVERTIBLE_TERM_TYPES.DEBT
      },
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      value: {
        handler (val) {
          if (val) {
            this.conversionDiscount = val.conversion_discount ? val.conversion_discount * 100 : 0
            this.interestTerms = val.interest_terms || {}
            this.convertibleDebtTerms = val.convertible_debt_terms || {}
            this.boardApprovalDate = val.board_approved_on ? moment(val.board_approved_on).format('MM/DD/YYYY') : ''
          }
        },
        immediate: true,
      },
      conversionDiscount: {
        handler (val) {
          if (val) {
            this.value.conversion_discount = val / 100
          }
        },
        immediate: true,
      },
      boardApprovalDate: {
        handler (val) {
          if (val) {
            this.value.board_approved_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      convertibleDebtTerms: {
        handler (val) {
          if (val && Object.keys(val).length > 0) {
            this.internalValue = { ...this.internalValue, convertible_debt_terms: val }
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateValue () {
        this.clearBlankData()
        this.$emit('update', this.value)
      },
      updateInterest (value) {
        if (value) {
          this.value.interest_terms = value
        } else {
          delete this.value.interest_terms
        }
      },
      updateType () {
        if (this.value.convertible_type === this.CONSTS.CONVERTIBLE_TERM_TYPES.SAFE) {
          delete this.value.convertible_debt_terms
          delete this.value.interest_terms
        }
        this.updateValue()
      },
      clearBlankData () {
        Object.keys(this.value).forEach((key) => {
          if (this.value[key] === null || this.value[key] === undefined) {
            delete this.value[key]
          }
        })
      },
    },
  }
</script>
