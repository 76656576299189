var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0 mt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":'Early exit multiple'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Early exit multiple","color":"primary","error-messages":errors,"options":{ locale: 'en-US', precision: 2 }},model:{value:(_vm.internalValue.early_exit_multiple),callback:function ($$v) {_vm.$set(_vm.internalValue, "early_exit_multiple", $$v)},expression:"internalValue.early_exit_multiple"}})]}}])})],1)],1),_c('v-row',{staticClass:" ma-0"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('common-input-switch',{attrs:{"label":"Warrant Coverage"},model:{value:(_vm.hasWarrantCoverage),callback:function ($$v) {_vm.hasWarrantCoverage=$$v},expression:"hasWarrantCoverage"}})],1)],1),(_vm.hasWarrantCoverage)?_c('v-row',{staticClass:" ma-0 mt-4"},[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Coverage price cap',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Coverage price cap","color":"primary","error-messages":errors,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},on:{"input":_vm.update},model:{value:(_vm.warrantCoverageData.coverage_price_cap),callback:function ($$v) {_vm.$set(_vm.warrantCoverageData, "coverage_price_cap", $$v)},expression:"warrantCoverageData.coverage_price_cap"}})]}}],null,false,3387138415)})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Coverage type',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Coverage type","placeholder":"Select coverage type","items":_vm.CONSTS.DEBT_CONVERTIBLE_COVERAGE_TYPES,"item-text":"title","item-value":"value","error-messages":errors},on:{"change":_vm.update},model:{value:(_vm.warrantCoverageData.coverage_type),callback:function ($$v) {_vm.$set(_vm.warrantCoverageData, "coverage_type", $$v)},expression:"warrantCoverageData.coverage_type"}})]}}],null,false,1248663531)})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Rate',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Rate","color":"primary","error-messages":errors,"options":{ locale: 'en-US', precision: 2 },"append-icon":"mdi-percent-outline"},on:{"input":_vm.update},model:{value:(_vm.warrantCoverageData.rate),callback:function ($$v) {_vm.$set(_vm.warrantCoverageData, "rate", $$v)},expression:"warrantCoverageData.rate"}})]}}],null,false,601863444)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }